import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Paper,
    Link,
    makeStyles,
    List,
    ListItem,
    // useMediaQuery,
    // useTheme,
} from '@material-ui/core';
import imageUrl from '../assets/images/profile.jpg';
import ostPed from '../assets/images/osteopatia_pediatrica.jpg';
import osteopatia from '../assets/images/osteopatia.jpg';
import motorie from '../assets/images/scienze_motorie.jpg';

const useStyle = makeStyles((theme) => ({
    paper: ({
        padding: 10,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom'
    })
}));
const Main = () => {

    const classes = useStyle();
    // const theme = useTheme();
    // const isMD = useMediaQuery(theme.breakpoints.down('md'));
    // console.log("media", isMD)
    return <Container>
        <Grid container spacing={2} direction={"row"}>
            <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.paper} style={{ height: 400, backgroundImage: `url(${imageUrl})` }} />
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>

                        <Paper className={classes.paper}
                        style={{ backgroundImage: `url(${osteopatia})` }}
                        >
                            <Typography variant="h3" align="left">
                                Osteopatia
                            </Typography>
                            <List>
                                <ListItem>
                                    <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                        {"Master di I livello in: "}<Link target="_blank" color="secondary" href="https://www.unipegaso.it/post-laurea/master-livello-1/sanita/osteopatia-in-collaborazione-con-sefo-e-aipu">Osteopatia, Università telematica Pegaso</Link>
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                        {"Diploma in: "}<Link target="_blank" color="secondary" href="https://icommitalia.com/">Osteopatia D.O., ICOMM Scuola di Osteopatia – Internationa College of Manual Medicine</Link>
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <List>
                                        <ListItem>
                                            <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                                {"Tirocinio presso: "}<Link target="_blank" color="secondary" href="https://www.fatebenefratelli-isolatiberina.it/it/CentroDiAritmologia">Centro Aritmie dell'ospedale Fatebenefratelli San Giovanni Calibita</Link>
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </ListItem>
                            </List>
                        </Paper>

                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>

                        <Paper className={classes.paper} style={{ backgroundImage: `url(${ostPed})` }}>
                            <List>
                                <Typography variant="h3" align="left">
                                    Osteopatia pediatrica
                                </Typography>
                                <ListItem>
                                    <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                        {"Diploma in: "}<Link target="_blank" color="secondary" href="https://cerdo.it/node/20">Osteopatia Pediatrica, C.E.R.D.O. - Centre pour l'étude la recherche et la diffusion ostéopathiques</Link>
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <List>
                                        <ListItem>
                                            <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                                {"Tirocinio presso: "}<Link target="_blank" color="secondary" href="http://osteobimbo.it/centro-di-eccellenza/">OSTEOBIMBO - Poliambulatorio Pediatrico Privato, Centro di Osteopatia e Riabilitazione Pediatrica</Link>
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </ListItem>
                            </List>
                        </Paper>

                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>

                        <Paper className={classes.paper} style={{ backgroundImage: `url(${motorie})`, backgroundPositionY: "top" }}>
                            <Typography variant="h3" align="left">
                                Management dello sport e delle attività motorie
                            </Typography>
                            <List>
                                <ListItem>
                                    <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                        {"Triennale in: "}<Link target="_blank" color="secondary" href="https://www.unipegaso.it/corsi-di-laurea/scienze-motorie">Scienze Motorie, Università telematica Pegaso</Link>
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <List>
                                        <ListItem>
                                            <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                                {"Tirocinio presso: "}<Link target="_blank" color="secondary" href="https://it-it.facebook.com/acdreal.campagnano">A.C.D. REAL CAMPAGNANO</Link>
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                                {"Tirocinio presso: "}<Link target="_blank" color="secondary" href="http://www.sportingvillage.it/">Sporting Village</Link>
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </ListItem>
                                <ListItem>
                                    <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                        {"Magistrale in: "}<Link target="_blank" color="secondary" href="https://www.unipegaso.it/corsi-di-laurea/management-sport-attivita-motorie">Management dello sport e delle attività motorie, Università telematica Pegaso</Link>
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <List>
                                        <ListItem>
                                            <Typography style={{ marginTop: 5 }} variant="body1" align="left">
                                                {"Tirocinio presso: "}<Link target="_blank" color="secondary" href="https://it-it.facebook.com/VolleyPrati/">Volley Prati ASD - Serie D</Link>
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </ListItem>
                            </List>
                        </Paper>

                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.paper} style={{ height: 400, backgroundImage: `url(${imageUrl})` }} />
            </Grid> */}
        </Grid>
        {/* <Grid container spacing={2} direction="row-reverse">
      <Grid item xs={6} md={8}>
        <Paper>
          <Typography variant="h3">
            Osteopatia
          </Typography>
          <Typography variant="body1">
            Lorem ipsum alksnfhjozynsiufhco afoiusnfouadhsn fhu a0shduvncoahsduifhaoiudsfi odhasiunhcdiohcoijahdsiofn
          </Typography>
        </Paper>
      </Grid>
    </Grid> */}
    </Container>
}

export default Main;