import { useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
// import useDimension from '../hooks/useDimension';
import { 
    Button, 
    Typography, 
    Dialog, 
    DialogActions, 
    DialogTitle, 
    Tooltip, 
    IconButton, 
    Link, 
    Grid, 
    Container, 
    makeStyles 
} from '@material-ui/core';
import { Waze, Map as MapIcon } from 'mdi-material-ui';
import { Close } from '@material-ui/icons';

const MAPS_LAT_INITIAL_CENTER = 41.94152406439337
const MAPS_LNG_INITIAL_CENTER = 12.535497919183026

const address = 'Piazza Elba, 5/a, 00141 Roma RM, Italia';
const telephone = '3348426495';

function Copyright() {
    return (
      <Typography variant="body2" style={{ color: "#fff" }} align="center">
        {'Copyright © '}
        <Link color="inherit" href="#">
          NS GROUP
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    mapsButton: {
      backgroundColor: "#ea4335!important",
      color: "white!important"
    },
    wazeButton: {
      backgroundColor: "#32ccfe!important",
      color: "white!important"
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
  
    divider: {
      margin: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(1)
    },
    marginBottom: {
      marginBottom: theme.spacing(1)
    },
    grid: {
      marginBottom: theme.spacing(2),
    },
    footer: {
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
      position: "sticked",
      bottom: 0,
      marginTop: 20,
      padding: theme.spacing(6, 0),
      boxShadow: `0px -2px 4px 0px rgb(0 0 0 / 12%), 
                  0px -4px 5px 0px rgb(0 0 0 / 14%), 
                  0px -1px 10px -1px rgb(0 0 0 / 20%)`
    },
  }));

const _Footer = ({ google }) => {

//   const { width } = useDimension();

  const [mapWidth, setMapWidth] = useState(100);
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();
  const openWithWaze = () => {
    window.open(`https://waze.com/ul?ll=${MAPS_LAT_INITIAL_CENTER},${MAPS_LNG_INITIAL_CENTER}&navigate=yes`)
  };

  const openWithNativeMaps = () => {
    if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") !== -1) ||
      (navigator.platform.indexOf("iPad") !== -1) ||
      (navigator.platform.indexOf("iPod") !== -1))
      window.open(`maps://maps.google.com/maps?daddr=${MAPS_LAT_INITIAL_CENTER},${MAPS_LNG_INITIAL_CENTER}&amp;ll=`);
    else /* else use Google */
      window.open(`https://maps.google.com/maps?daddr=${MAPS_LAT_INITIAL_CENTER},${MAPS_LNG_INITIAL_CENTER}&amp;ll=`);
  };

  const handleAddressClick = (e) => {
    e.preventDefault();
    setOpenDialog(true);
  };
  
  const handleMarkClick = () => {
    setOpenDialog(true);
  };

  const calculateMapWidth = mapContainerRef => {
    const translatePX = (px) => Number(px.replace("px", "")) 
    const paddingLeft = translatePX(window.getComputedStyle(mapContainerRef).paddingLeft)
    const paddingRight = translatePX(window.getComputedStyle(mapContainerRef).paddingRight)
    return mapContainerRef.offsetWidth - paddingRight - paddingLeft;
  };
  
  return (
    <>
      <footer id="contact-us" className={classes.footer}>
        <Container  maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={8} lg={8}>
              <div style={{ height: 436, width: "100%" }} ref={mapContainerRef => mapContainerRef && setMapWidth(calculateMapWidth(mapContainerRef))}>
                <Map
                  google={google}
                  zoom={15}
                  containerStyle={{ width: mapWidth, height: 400 }}
                  style={{ width: mapWidth, height: 400 }}
                  initialCenter={{ lat: MAPS_LAT_INITIAL_CENTER, lng: MAPS_LNG_INITIAL_CENTER }}
                >
                  <Marker
                    onClick={handleMarkClick}
                    position={{ lat: MAPS_LAT_INITIAL_CENTER, lng: MAPS_LNG_INITIAL_CENTER }}
                  />
                </Map>
              </div>
            </Grid>

            <Grid item xs={12} md={4} lg={4} style={{ display: "flex", flexDirection: "column", alignSelf: "flex-start" }}>
              <Typography variant="h4" align="center" gutterBottom>
                Contattami
              </Typography>
              <Typography variant="subtitle1" align="center" >
                Indirizzo
              </Typography>
              <Link href="#" onClick={handleAddressClick} className={ classes.marginBottom } color="secondary" variant="body1" align="center">
                {address}
              </Link>
              <Typography variant="subtitle1" align="center">
                Telefono
              </Typography>
              <Link href={`tel:${telephone}`} variant="body1" color="secondary" align="center" className={ classes.marginBottom }>
                Chiama il {telephone}
              </Link>
            </Grid>
          </Grid>
          <Copyright />
        </Container>
      </footer>
      <Dialog
        maxWidth="sm"
        open={openDialog}
      // onKeyPress={this.handleKeyPress}
      // onExited={this.handleExited}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Apri con</Typography>
          <Tooltip title="Chiudi">
            <IconButton
              className={classes.closeButton}
              onClick={() => setOpenDialog(false)}
            >
              {/* <i className="fa fa-times"></i> */}
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogActions>
          <Button
            // className="button-bg"
            className={classes.wazeButton}
            variant="contained"
            onClick={openWithWaze}
          >
            {'Waze '}<Waze />
          </Button>
          <Button
            // className="button-bg"
            className={classes.mapsButton}
            variant="contained"
            onClick={openWithNativeMaps}
          >
            {'Mappe '}<MapIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY
})(_Footer);
