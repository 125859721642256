import './App.css';
import { 
  MuiThemeProvider, 
  createTheme, 
} from '@material-ui/core';
import Footer from './components/Footer';
import Main from './components/Main';
import Header from './components/Header';


const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        paddingTop: "0px!important",
        paddingBottom: "0px!important"
      }
    },
    MuiList: {
      root: {
        paddingTop: "0px!important",
        paddingBottom: "0px!important"
      }
    }
  },
  palette: {
    // primary: {
    //   main: "#4caf50",
    //   dark: "#357a38",
    //   light: "#6fbf73",
    //   contrastText: "#222"
    // },
    // secondary: {
    //   main: "#af4e4c",
    //   dark: "#7a3635",
    //   light: "#bf716f",
    //   constrastText: "#222"
    // }
    primary: {
      main: "#03a9f4",
      dark: "#0276aa",
      light: "#35baf6",
      contrastText: "#222"
    },
    secondary: {
      main: "#f45303",
      dark: "#aa3a02",
      light: "#f67535",
      constrastText: "#222"
    }
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <Main />
        <Footer />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
