import React from 'react';
import {
    AppBar,
    Box,
    Typography
} from '@material-ui/core';
// import logo from '../assets/images/logo.svg';

const Header = () => {
    return <AppBar position="static" color="primary" style={{ marginBottom: 20 }}>
        <Box display="flex" justifyContent="space-around">
            {/* <img alt="...logo" src={logo} width={50} /> */}
            <Typography variant="h2">
                Dr. Edoardo De Angelis
            </Typography>
        </Box>
    </AppBar>
}

export default Header;